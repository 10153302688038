// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ResourceNew from "./ResourceNew.res.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourceCategoryAsFilter from "../../../models/ResourceCategoryAsFilter.res.js";

function ResourceNew__JsBridge$default(props) {
  return JsxRuntime.jsx(ResourceNew.make, {
              categories: Belt_SortArray.stableSortBy(Belt_Array.map(props.props.categories, ResourceCategoryAsFilter.fromJs), (function (x1, x2) {
                      return Caml.string_compare(x1.title, x2.title);
                    }))
            });
}

var $$default = ResourceNew__JsBridge$default;

export {
  $$default as default,
}
/* ResourceNew Not a pure module */
