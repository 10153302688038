// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Breadcrumb from "../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as ResourceNewForm from "./ResourceNewForm.res.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourceNewScss from "./ResourceNew.scss";

var css = ResourceNewScss;

function ResourceNew(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Breadcrumb.make, {
                      breadcrumbLinks: [
                        {
                          linkName: "Resources Dashboard",
                          linkPath: Routes_Resource.Dashboard.index
                        },
                        {
                          linkName: "New Resource",
                          linkPath: Routes_Resource.Dashboard.$$new
                        }
                      ]
                    }),
                JsxRuntime.jsx(H1.make, {
                      className: css.pageTitle,
                      children: "New Resource"
                    }),
                JsxRuntime.jsx(ResourceNewForm.make, {
                      categories: props.categories
                    })
              ],
              className: css.container
            });
}

var make = ResourceNew;

export {
  css ,
  make ,
}
/* css Not a pure module */
